<template>
  <line-chart :chart-data="chartData" :options="options" />
</template>

<script>
export default {
  props: {
    monitor: { required: true, type: Object },
    period: { required: true, type: String }
  },
  data() {
    return {};
  },
  computed: {
    yAxisMax() {
      const ping = this.$_.maxBy(this.pings, ping => ping.value);
      return ping ? ping.value : 0;
    },
    yAxisMin() {
      const ping = this.$_.minBy(this.pings, ping => ping.value);
      return ping ? ping.value : 0;
    },
    pings() {
      return this.$_.orderBy(
        this.monitor.response_times,
        [ping => ping.datetime],
        ["asc"]
      );
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0,
                maxTicksLimit: 3,
                callback: value => {
                  const time = this.$moment(value);
                  switch (this.period) {
                    case "hour":
                      return time.format("mm");
                    case "day":
                      return time.format("kk:mm");
                    case "week":
                    case "month":
                      return time.format("DD/MM");
                    case "year":
                    case "all":
                      return `${time.format("W")} (${time.format("Y")})`;
                    default:
                      throw `Unknown period: ${this.period}`;
                  }
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                max: this.$_.ceil(this.yAxisMax + this.yAxisMin, -1),
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            title: (tooltipItem, data) => {
              const time = data.labels[tooltipItem[0].index];
              switch (this.period) {
                case "day":
                  return this.$moment(time).format("DD/MM/YY kk:mm");
                case "week":
                  return this.$moment(time).format("DD/MM/YY kk:mm");
                case "month":
                  return this.$moment(time).format("DD/MM/YY");
                case "year":
                case "all":
                  return `week ${this.$moment(time).format(
                    "W"
                  )} (${this.$moment(time).format("Y")})`;
                default:
                  throw `Unknown period: ${this.period}`;
              }
            }
          }
        }
      };
    },
    chartData() {
      let labels = [],
        responseTimeData = [];
      this.$_.forEach(this.pings, ping => {
        labels.push(this.$moment.unix(ping.datetime).toDate());
        responseTimeData.push(ping.value);
      });

      return {
        labels: labels,
        datasets: [
          {
            label: "Response time (ms)",
            fill: false,
            backgroundColor: "rgb(54, 162, 235)",
            borderColor: "rgb(54, 162, 235)",
            data: responseTimeData
          },
          {
            pointRadius: 0,
            label: "Average response time (ms)",
            fill: false,
            backgroundColor: "rgb(255, 255, 255)",
            borderColor: "rgb(75, 192, 192)",
            borderDash: [5, 5],
            data: this.$_.map(labels, () => this.monitor.average_response_time)
          }
        ]
      };
    }
  }
};
</script>
